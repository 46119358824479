import React from "react"

import Layout from "../components/layout"
import { grayGradient2 } from "../services/constants"
import styles from "./success.module.scss"
import Header from "../components/header"
import Footer from "../components/footer"
import globalStyles from "../services/globalStyles.module.scss"
import { graphql } from "gatsby";


class SuccessPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: false,
      name: false,
    }
  }
  componentDidMount() {
    const urlArrEmail = document.URL.split("email=");
    const email = urlArrEmail.length != 1 && decodeURIComponent(urlArrEmail[urlArrEmail.length - 1]);
    const urlArrName = document.URL.split("name=");
    const name = urlArrName.length != 1 && decodeURIComponent(urlArrName[urlArrName.length - 1]);

    this.setState({ email, name });
  }
  render() {
    const { texts: { _ } } = this.props.data;
    const languageCode = this.props.pageContext && this.props.pageContext.lang ? this.props.pageContext.lang : "en";

    return (
      <Layout nologo headers={[<Header num={1} translations={_} fixed languageCode={languageCode}/>]} translations={_} languageCode={languageCode}>
        <div className={styles.container}>
          <h1 className={styles.header}> {_.success.header} { _.success.have_a_great_day + " " + this.state.name}
          </h1>
          {
            (<h2>
              {_.success.header} {this.state.name}
            </h2>)
          }
        </div>
        <Header num={2} translations={_} className={globalStyles.hideOnMobile} nologo languageCode={languageCode}/>
        <Footer translations={_} languageCode={languageCode}/>
      </Layout>
    )
  }
}

export default SuccessPage

export const query = graphql`
  query Success($langFile: String) {
    site {
      siteMetadata {
        ip_stack_key
      }
    }
    texts: file(relativePath: {eq: $langFile}) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        success {
          header
          email_sent
          have_a_great_day
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    }
  }
`